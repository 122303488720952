.footer {
    padding: 0 $main-spacing;
    background-color: $color-alabaster;
    color: $color-gray;
    
    &__container {
        max-width: $main-max-width;
        margin: 0 auto;
        padding: $spacing-l 0;
        
        font-size: $font-size-s;
        text-align: center;
        line-height: $line-height-l;
    }
}
