h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}

b,
em,
i,
strong {
    font-style: inherit;
}

a {
    color: inherit;
    text-decoration: none;
}

a, button, label {
    @include remove-tap-highlight;
    outline: 0;
}

small {
    font-size: $font-size-s;
}
