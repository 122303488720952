/**
 * 1. We use the sticky footer pattern of Philip Walton:
 *    https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
 */
html {
    font-size: $font-size-l;
    box-sizing: border-box;
    text-size-adjust: 100%;
    height: 100%; // 1
}

*,
*::after,
*::before {
    box-sizing: inherit;
}

body {
    display: flex; // 1
    flex-flow: column; // 1
    min-height: 100%; // 1
    line-height: $line-height-normal;
    font-family: $font-primary;
    font-size: $font-size-m;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: 'liga', 'kern';
    
    &.--min-width {
        min-width: 768px;
    }
}

input,
select,
textarea {
    font-family: $font-primary;
}
