// -----------------------------------------------------------------------------
// COLOR PALETTE
// -----------------------------------------------------------------------------

// COLORS
// http://chir.ag/projects/name-that-color/
$color-white      : #fff;
$color-black      : #000;
$color-alabaster  : #f9f9f9;
$color-alto       : #ddd;
$color-gallery    : #eee;
$color-silver     : #bbb;
$color-emperor    : #555;
$color-gray       : #8d8d8d;
$color-mineshaft  : #222222;
$color-red        : #f20000;
$color-milanored  : #c80606;

// DEDICATED COLORS
$color-text      : $color-mineshaft;
$color-text-faded: $color-emperor;
$color-lines     : rgba($color-gray, 0.7);
$color-accent    : $color-red;
$color-button    : $color-emperor;

// -----------------------------------------------------------------------------
// SPACING
// -----------------------------------------------------------------------------

$spacing-xxs: 5px;
$spacing-xs : 10px;
$spacing-s  : 15px;
$spacing-m  : 20px;
$spacing-l  : 30px;
$spacing-xl : 40px;
$spacing-xxl: 50px;
$spacing-xxxl: 60px;

$spacing: (
    xxs : $spacing-xxs,
    xs  : $spacing-xs,
    s   : $spacing-s,
    m   : $spacing-m,
    l   : $spacing-l,
    xl  : $spacing-xl,
    xxl : $spacing-xxl,
);

// -----------------------------------------------------------------------------
// LAYOUT
// -----------------------------------------------------------------------------

$main-max-width: 800px;
$main-spacing: $spacing-xl;

// -----------------------------------------------------------------------------
// TYPOGHRAPHY
// -----------------------------------------------------------------------------

$font-primary: "Helvetica Neue", Helvetica, Arial sans-serif;
$font-decor  : "PlayfairDisplay", Times, serif;

$font-size-xs       : 11px;
$font-size-s        : 12px;
$font-size-m        : 14px;
$font-size-l        : 16px;

$font-size-title-m  : 40px;
$font-size-title-l  : 50px;

$line-height-xs: 1.2;
$line-height-s: 1.4;
$line-height-m: 1.6;
$line-height-l: 1.8;
$line-height-normal: $line-height-m;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold  : 600;

// -----------------------------------------------------------------------------
// Z-INDICES
// -----------------------------------------------------------------------------
// $zindex-modal: 1000;

// -----------------------------------------------------------------------------
// TRANSITIONS
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// COMPONENTS
// -----------------------------------------------------------------------------

$control-height: 36px;
$config-bar-min-height: 360px;
$config-bar-title-height: 100px;
$config-card-media-size: 122px;

$btn-height: $control-height;
