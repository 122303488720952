/**
 * The micro clearfix
 * 
 * @see http://nicolasgallagher.com/micro-clearfix-hack/
 */
@mixin clearfix {
    *zoom: 1;

    &::after,
    &::before {
        content: '';
        display: table;
    }

    &::after {
        clear: both;
    }
}
