/**
 * 1. We use the sticky footer pattern of Philip Walton:
 *    https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
 */
.main {
    flex: 1 0 auto; // 1
    padding: 0 $main-spacing;
    background-color: $color-white;
    
    &__container {
        max-width: $main-max-width;
        margin: 0 auto;
        padding: $spacing-xl 0;
    }
}
