.textfield {
    &__field {
        display: flex;
        flex-flow: column;
    }

    &__input {
        order: 1;
        font-size: $font-size-m;
        padding: $spacing-xxs 0 $spacing-xs;
        border: none;
        border-bottom: 1px solid $color-alto;
        resize: none;
        outline: 0;
        border-radius: 0;
    }
    
    &__label {
        font-size: $font-size-s;
        font-weight: $font-weight-medium;
        color: $color-gray;
        pointer-events: none;
    }
}
