.table {
    $_border-width: 1px;
    $_border-color: $color-gallery;
    
    &__title {
        display: inline-block;
        font-size: $font-size-s;
        font-weight: $font-weight-medium;
        text-transform: uppercase;
        letter-spacing: 0.01em;
        color: $color-emperor;
    }
    
    &__filter {
        display: inline-block;
        margin-left: $spacing-xs;
        font-size: $font-size-s;
        font-weight: $font-weight-medium;
        color: $color-gray;
    }
    
    &__body {
        margin-top: $spacing-xxs;
        border-top: $_border-width solid $_border-color;
    }
    
    &__item {
        padding: $spacing-m $spacing-s;
        border-bottom: $_border-width solid $_border-color;
    }
    
    &__pagination {
        display: flex;
        justify-content: flex-end;
        margin-top: $spacing-m;
    }
}
