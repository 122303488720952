.btn {
    $_height: $btn-height;
    
    position: relative;
    display: inline-block;
    font-family: $font-primary;
    font-size: $font-size-xs;
    line-height: normal;
    font-weight: $font-weight-bold;
    text-transform: none;
    text-decoration: none;
    text-align: left;
    letter-spacing: 0.05em;
    color: $color-white;
    background: $color-emperor;
    border: none;
    outline: none;
    border-radius: $_height / 2;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    height: $_height;
    white-space: nowrap;
    text-transform: uppercase;
    padding: 0 ($_height / 2 + $spacing-xs);

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    &__text {
        display: block;
    }
    
    &--accent {
        background-color: $color-accent;
    }
    
    &--secondary,
    &--inverted {
        border-width: 2px;
        border-style: solid;
    }
    
    &--secondary {
        color: $color-emperor;
        border-color: $color-alto;
        background-color: $color-white;
    }
    
    &--inverted {
        border-color: rgba($color-alto, 0.4);
        background-color: transparent;
    }
}