/**
 * Replace `$search` with `$replace` in `$string`
 * @author Hugo Giraudel
 * @see https://css-tricks.com/snippets/sass/str-replace-function/
 * 
 * @param {string} $string - Initial string
 * @param {string} $search - Substring to replace
 * @param {string} $replace ('') - New value
 * @return {string} - Updated string
 */

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @if $index {
        @return str-slice($string, 1, $index - 1)+$replace+str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
}


/**
 * Remove the unit of a given number
 * 
 * @param {number} $_number to remove unit from
 * @return {number} an unitless number
 */

@function strip-unit($_number) {
    @if type-of($_number)=='number' and not unitless($_number) {
        @return $_number / ($_number * 0 + 1);
    }
    @return $_number;
}


/**
 * Encode SVG to use them as data-uri for background-image
 * Encodes <, > and #.
 * 
 * @see https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
 * 
 * @param {string} $string - SVG code to encode
 * @returns {string} - encoded String
 */

@function svg-url($svg) {
    // Add missing namespace
    @if not str-index($svg, xmlns) {
        $svg: str-replace($svg, '<svg', '<svg xmlns="http://www.w3.org/2000/svg"');
    }
    // Chunk up string in order to avoid 
    // "stack level too deep" error
    $encoded: '';
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($svg)/$slice);
    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $slice - 1);
        // Encode 
        $chunk: str-replace($chunk, '"', '\'');
        $chunk: str-replace($chunk, '%', '%25');
        $chunk: str-replace($chunk, '&', '%26');
        $chunk: str-replace($chunk, '#', '%23');
        $chunk: str-replace($chunk, '{', '%7B');
        $chunk: str-replace($chunk, '}', '%7D');
        $chunk: str-replace($chunk, '<', '%3C');
        $chunk: str-replace($chunk, '>', '%3E');
        // The maybe list 
        // Keep size and compile time down
        // ... only add on documented fail 
        // 
        // $chunk: str-replace($chunk, '|', '%7C');
        // $chunk: str-replace($chunk, '[', '%5B');
        // $chunk: str-replace($chunk, ']', '%5D');
        // $chunk: str-replace($chunk, '^', '%5E');
        // $chunk: str-replace($chunk, '`', '%60');
        // $chunk: str-replace($chunk, ';', '%3B');
        // $chunk: str-replace($chunk, '?', '%3F');
        // $chunk: str-replace($chunk, ':', '%3A');
        // $chunk: str-replace($chunk, '@', '%40');
        // $chunk: str-replace($chunk, '=', '%3D');      
        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }
    @return url("data:image/svg+xml,#{$encoded}");
}