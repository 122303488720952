.link {
    position: relative;
    display: inline-block;
    padding: 0;
    font-family: $font-primary;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    text-transform: none;
    text-decoration: none;
    text-align: left;
    color: inherit;
    background-color: transparent;
    border: none;
    outline: none;
    border-radius: 0;
    vertical-align: baseline;
    cursor: pointer;
    user-select: none;
    
    &__text {
        position: relative;
    }
    
    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid currentColor;
        transition: opacity 0.15s linear;
        opacity: 0.5;
    }
    
    &:hover::after {
        opacity: 0.8;
    }
}