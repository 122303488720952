.notification {
    display: flex;
    padding: $spacing-s $spacing-s $spacing-m;
    background-color: $color-alabaster;
    border-left: 2px solid $color-accent;
    border-bottom: 1px solid rgba($color-black, 0.16);
    
    &__icon,
    &__action {
        flex: 0 0 auto;
    }
    
    &__icon {
        margin-right: $spacing-xs;
    }
    
    &__text {
        font-size: $font-size-s;
    }
    
    &__action {
        margin-top: 5px;
        margin-left: 80px;
    }
}
