/**
 * Hide visually
 */
@mixin hide {
    @include sr-only;
    visibility: hidden;
}

/**
 * Only display content to screen readers
 * 
 * @see http://a11yproject.com/posts/how-to-hide-content
 * @see http://hugogiraudel.com/2016/10/13/css-hide-and-seek/
 */
@mixin sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
}

/**
 * Use in conjunction with .sr-only to only display content when it's focused.
 * 
 * Useful for "Skip to main content" links
 * @see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
 * 
 * Credit: HTML5 Boilerplate
 */
@mixin sr-only-focusable {
    &:active,
    &:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;
        clip-path: none;
    }
}
