.header {
    position: relative;
    padding: 0 $main-spacing;
    color: $color-white;
    
    &__container {
        max-width: $main-max-width;
        margin: 0 auto;
        padding: $spacing-xxxl 0;
    }
    
    &__title {
        margin: 0 auto;
        text-align: center;
        font-family: $font-decor;
        font-size: $font-size-title-m;
        letter-spacing: 0.01em;
    }
    
    &__subtitle {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: $spacing-xxs auto 0;
        font-weight: $font-weight-medium;
        letter-spacing: 0.01em;
        
        &::before,
        &::after {
            content: '';
            flex: 0 1 auto;
            display: block;
            width: 80px;
            height: 1px;
            background-color: rgba($color-white, 0.4);
        }
        
        &-text {
            flex: 0 0 auto;
            margin: 0 $spacing-m;
            display: block;
        }
        
        &-icon {
            display: inline-block;
            transform: translateY(-2px);
            margin-right: 3px;
        }
    }
    
    &__action {
        position: absolute;
        top: $spacing-m;
        right: $spacing-m;
    }
}
