.chip {
    $_size: 40px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    width: $_size;
    height: $_size;
    border: 1px solid $color-alto;
    border-radius: 50%;
    color: $color-emperor;
}
