.login {
    color: $color-white;
    max-width: 400px;
    margin: 0 auto;
    padding: $spacing-l 0;
    
    &__horse {
        display: block;
        margin: 0 auto;
        width: 30px;
        height: 42px;
        background: url("../images/horse.svg") center no-repeat;
    }
    
    &__title {
        margin: $spacing-m auto 0;
        text-align: center;
        font-family: $font-decor;
        font-size: $font-size-title-l;
        letter-spacing: 0.01em;
    }
    
    &__subtitle {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: $spacing-xxs auto 0;
        font-weight: $font-weight-medium;
        letter-spacing: 0.01em;
        
        &::before,
        &::after {
            content: '';
            flex: 1 1 auto;
            display: block;
            width: 100%;
            height: 1px;
            background-color: rgba($color-white, 0.4);
        }
        
        &-text {
            flex: 0 0 auto;
            margin: 0 $spacing-m;
            display: block;
        }
    }
    
    &__form {
        margin-top: 90px;
        padding: $spacing-l;
        color: $color-text;
        background-color: $color-white;
    }
    
    &__action {
        text-align: right;
    }
    
    &__small {
        margin-top: $spacing-l;
        font-size: $font-size-s;
        text-align: center;
        line-height: $line-height-l;
    }
}
