.download {
    display: flex;
    
    &__icon,
    &__action {
        flex: 0 0 auto;
    }
    
    &__icon {
        margin-right: $spacing-xs;
    }
    
    &__description {
        flex: 1 1 auto;
    }
    
    &__text {
        font-size: $font-size-s;
        color: $color-emperor;
    }
    
    &__action {
        display: flex;
        margin-top: 5px;
        margin-left: 80px;
        
        > * {
            margin-left: $spacing-xs;
        }
    }
}
