/**
 * Polyfill CSS `object-fit` on IE11, Edge
 * 
 * @see https://github.com/bfred-it/object-fit-images
 * @see https://developer.mozilla.org/de/docs/Web/CSS/object-fit
 * 
 * @param {string} $_object-fit - fill, contain, cover, none, scale-down
 */
@mixin object-fit($_object-fit: cover) {
    object-fit: $_object-fit;
    font-family: 'object-fit: #{$_object-fit};';
}
