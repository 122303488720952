// NOTE Named SVG icons will be automatically generated to
// src/sass/generated/icon-generated.scss
.icon {
    display: inline-block;
    vertical-align: middle;
    pointer-events: none;
    fill: currentColor;
    
    svg {
        display: block;
        width: 100%;
        height: 100%;
    }
    
    &--fill-height {
        display: block;
        height: 100%;
    }
}
