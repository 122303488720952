// This is an auto generated file.
// Gulp Task: /gulpfile.js/tasks/task.svg.js
// Template:  /gulpfile.js/resources/template-svg-icons.scss

.icon-sprite {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

.icon {

    &--arrow-left {
        width: 4px;
        height: 7px;
    }

    &--arrow-right {
        width: 4px;
        height: 7px;
    }

    &--document {
        width: 14px;
        height: 18px;
    }

    &--none {
        width: 24px;
        height: 24px;
    }

    &--profile {
        width: 14px;
        height: 16px;
    }

    &--question {
        width: 18px;
        height: 18px;
    }

    &--rocket {
        width: 18px;
        height: 18px;
    }

}

$icon-arrow-left-width: 4px;
$icon-arrow-left-height: 7px;
$icon-arrow-right-width: 4px;
$icon-arrow-right-height: 7px;
$icon-document-width: 14px;
$icon-document-height: 18px;
$icon-none-width: 24px;
$icon-none-height: 24px;
$icon-profile-width: 14px;
$icon-profile-height: 16px;
$icon-question-width: 18px;
$icon-question-height: 18px;
$icon-rocket-width: 18px;
$icon-rocket-height: 18px;
