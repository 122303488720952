.pagination {
    position: relative;
    font-size: $font-size-s;
    font-weight: $font-weight-medium;
    
    &__list {
        display: flex;
        justify-content: center;
    }
    
    &__list-item {
        display: block;
        width: 28px;
        height: 28px;
        margin: 0 2px;
    }
    
    &__link,
    &__ellipsis,
    &__pager {
        display: block;
    }
    
    &__link,
    &__ellipsis {
    }
    
    &__link,
    &__ellipsis,
    &__pager {
        padding-left: $spacing-xxs;
        padding-right: $spacing-xxs;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        text-align: center;
        border: 1px solid $color-alto;
        border-radius: 2px;
        letter-spacing: -0.01em;
        user-select: none;
        
        &.is-active {
            border-bottom-color: $color-accent;
        }
    }
}
